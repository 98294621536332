<template>
  <div>
    <el-row type="flex" justify="end" style="margin: 20px 0">
      <el-button :loading="pushLoading" type="success" @click="pushInfo()">推送</el-button>
      <el-button :loading="saveLoading" type="primary" @click="saveInfo(1)">{{ $t('button.preservation') }}</el-button>
      <el-button @click="$router.go(-1)">取消</el-button>
    </el-row>
    <el-row type="flex" class="bgFFF" justify="center">
      <el-col :span="14">
        <el-form label-width="120px" :rules="formRules" ref="ruleForm" :model="formData">
          <!-- <el-form-item label="图标:"
                        prop="imgUrl"
                        ref="imgRuleLabel">
            <el-upload :on-remove="handleRemove"
                       :on-exceed="handleExceed"
                       :before-upload="beforeAvatarUpload"
                       :on-change="handleEditChange"
                       :limit="1"
                       :on-success="handleAvatarSuccess"
                       :file-list="fileList"
                       list-type="picture-card"
                       :headers="uploadHeader"
                       name="image"
                       :class="{ hide: hideUploadEdit }"
                       action="/acb/2.0/systems/loginUser/upload">
              <div slot="tip"
                   class="el-upload__tip">请上传图片宽高比1:1，大小100KB以内，支持格式.jpg .png</div>
              <i class="el-icon-plus" />
            </el-upload>
          </el-form-item> -->
          <el-form-item label="标题:" prop="title">
            <el-input
              v-model="formData.title"
              maxlength="16"
              show-word-limit
              placeholder="请输入推送标题"
            />
          </el-form-item>
          <!-- <el-form-item label="推送分类:">
            <el-select v-model="formData.category">
              <el-option label="系统通知"
                         :value="1" />
              <el-option label="活动通知"
                         :value="2" />
              <el-option label="资讯热点"
                         :value="3" />
              <el-option label="商品推介"
                         :value="4" />
            </el-select>
          </el-form-item> -->
          <el-form-item label="内容:" prop="content">
            <el-input
              type="textarea"
              :rows="3"
              v-model="formData.content"
              maxlength="50"
              show-word-limit
              placeholder="请输入推送内容的具体文案"
            />
          </el-form-item>
          <el-form-item label="目标应用:">
            <el-select v-model="formData.targetTerminal">
              <el-option label="全国爱泊车" :value="1" />
              <el-option label="石家庄爱泊车" :value="2" />
            </el-select>
          </el-form-item>
          <el-form-item label="跳转类型:">
            <el-radio-group v-model="formData.jumpType" @change="radioChange">
              <el-radio :label="1">启动APP</el-radio>
              <el-radio :label="2">页面URL</el-radio>
              <el-radio :label="3">应用路径</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-show="showURL">
            <el-input
              v-model.trim="formData.pageUrl"
              placeholder="请输入推送横幅点击后跳转落地页url链接"
              maxlength="120"
              show-word-limit
            />
          </el-form-item>
          <el-form-item v-show="showPath">
            <el-cascader
              v-model="formData.applicationPath"
              clearable
              :options="jumpOptions"
              :props="{ expandTrigger: 'hover', checkStrictly: true }"
              style="display: block"
            />
          </el-form-item>
          <el-form-item :label="$t('searchModule.Push_time')">
            <el-date-picker
              v-model="formData.pushTime"
              type="datetime"
              placeholder="（非必填）按需计划任务"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
          </el-form-item>
          <el-form-item label="推送用户标签:" prop="pushTags">
            <el-select v-model="formData.pushTags" multiple>
              <el-option
                :label="item.labelName"
                :value="item.memberTagId"
                v-for="item in labelList"
                :key="item.labelId"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="任务描述:">
            <el-input
              type="textarea"
              :rows="3"
              v-model="formData.remarks"
              maxlength="50"
              show-word-limit
              placeholder="（非必填）请输入关于本次推送任务的背景及相关备注"
            />
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import moment from "moment";
// import { resolve } from "dns";
// import { reject } from "q";
import { request } from "../../../common/js/public";
export default {
  data() {
    return {
      isEdit: false,
      showURL: false,
      showPath: false,
      saveLoading: false,
      pushLoading: false,
      labelList: [],
      formData: {
        title: "",
        category: 1,
        content: "",
        targetTerminal: 1,
        jumpType: 1,
        pushTime: "",
        pushTags: [],
        remarks: "",
        pageUrl: "",
        applicationPath: [],
        imgUrl: "",
      },
      fileList: [],
      imageUrl: "",
      jumpOptions: [],
      // expireTimeOption: {
      //   disabledDate(date) {
      //     return date.getTime() <= Date.now();
      //   }
      // },
      uploadHeader: {
        "Session-Id": sessionStorage.token,
      },
      formRules: {
        title: [
          {
            required: true,
            message: "请输入标题",
            trigger: "blur",
          },
        ],
        content: [
          {
            required: true,
            message: "请输入内容",
            trigger: "blur",
          },
        ],
        pushTags: [
          {
            required: true,
            message: "请选择用户标签",
            trigger: "blur",
          },
        ],
      },
      pushId: this.$route.query.pushId ? this.$route.query.pushId : "",
      hideUploadEdit: false,
    };
  },
  mounted() {
    this.getAppPageList();
    this.getLabel();
    if (this.pushId) {
      this.isEdit = true;
      this.getDetailData();
    }
  },
  methods: {
    // 编辑获取详情
    getDetailData() {
      const opt = {
        url: "/acb/2.0/pushManage/detail/" + this.$route.query.pushId,
        method: "get",
        data: {},
        success: (res) => {
          let data = res.value;
          this.pushId = data.pushId;
          this.formData.pushId = data.pushId;
          this.formData.pushStatus = data.pushStatus;
          this.formData.title = data.title;
          this.formData.category = data.category;
          this.formData.content = data.content;
          this.formData.targetTerminal = data.targetTerminal;
          this.formData.jumpType = data.jumpType;
          this.formData.jumpUrl = data.jumpUrl;
          this.formData.pushTime = data.pushTime;
          this.formData.remarks = data.remarks;
          data.imgUrl ? (this.fileList = [{ url: data.imgUrl }]) : (this.fileList = []);
          data.imgUrl ? (this.formData.imgUrl = data.imgUrl) : (this.formData.imgUrl = "");
          data.jumpType == 2
            ? (this.formData.pageUrl = data.jumpUrl)
            : data.jumpType == 3
            ? (this.formData.applicationPath = data.jumpUrlList)
            : "";
          this.$nextTick(() => {
            if (data.pushTags.indexOf(",") != -1) {
              data.pushTags.split(",").forEach((item, i) => {
                this.formData.pushTags.push(String(item));
              });
            } else {
              this.formData.pushTags.push(String(data.pushTags));
            }
          });
          this.radioChange(data.jumpType);
          this.hideUploadEdit = this.fileList.length >= 1;
        },
      };
      this.$request(opt);
    },
    // 获取跳转路径列表
    getAppPageList() {
      return new Promise((resolve, reject) => {
        const opt = {
          url: "/acb/2.0/advert/appPageList",
          method: "get",
          data: { parentId: 0 },
          success: (res) => {
            const value = res.value;
            this.getFormattedList(value);
            this.jumpOptions = res.value;
            resolve();
          },
        };
        this.$request(opt);
      });
    },
    // 将数据格式重排
    getFormattedList(value) {
      value.forEach((item) => {
        const keyMap = {
          pagePath: "value",
          pageName: "label",
          childrenList: "children",
        };
        for (const key in item) {
          const newKey = keyMap[key];
          if (newKey) {
            item[newKey] = item[key];
            delete item[key];
          }
        }
        if (item.children) {
          this.getFormattedList(item.children);
        }
      });
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
      this.formData.imgUrl = "";
      this.hideUploadEdit = fileList.length >= 1;
    },
    handleExceed(files, fileList) {
      this.$message.error("只能上传一个文件");
    },
    handleEditChange(file, fileList) {
      this.hideUploadEdit = fileList.length >= 1;
    },
    beforeAvatarUpload(file) {
      const isImg = file.type === "image/jpeg" || file.type === "image/png";
      const isLt100k = file.size / 1024 < 100;
      if (!isImg) {
        this.$message.error("上传头像图片只能是 JPG 、PNG格式!");
      }
      if (!isLt100k) {
        this.$message.error("上传头像图片大小不能超过 100k!");
      }
      return isImg && isLt100k;
    },
    handleAvatarSuccess(res, file, fileList) {
      if (res.state == 0) {
        this.$refs["imgRuleLabel"].clearValidate();
        this.formData.imgUrl = res.value;
      }
    },
    // 跳转类型
    radioChange(val) {
      this.showURL = val === 2;
      this.showPath = val === 3;
    },
    // 推送
    async pushInfo() {
      this.saveInfo(2).then((pushId) => {
        let pushIdS = "";
        if (this.$route.query.pushId) {
          pushIdS = this.$route.query.pushId;
        } else {
          pushIdS = pushId;
        }
        this.pushLoading = true;
        const opt = {
          url: "/acb/2.0/pushManage/send/" + pushIdS,
          method: "post",
          data: {},
          success: (res) => {
            this.pushLoading = false;
            this.$router.push("/appPushManage");
          },
          fail: (res) => {
            this.pushLoading = false;
          },
        };
        this.$request(opt);
      });
    },
    // 保存
    saveInfo(arg) {
      return new Promise((resolve, reject) => {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            this.saveLoading = true;
            // this.formData.pushId = this.loadData.pushId;
            let typeFlag = this.formData.jumpType; // 跳转url
            if (typeFlag == "2") {
              this.formData.jumpUrl = this.formData.pageUrl;
            } else if (typeFlag == "3") {
              let leg = this.formData.applicationPath.length;
              if (leg > 0) {
                this.formData.jumpUrl = this.formData.applicationPath[leg - 1];
              }
            } else {
              this.formData.jumpUrl = "";
            }
            // 推送状态
            if (arg == 1) {
              this.formData.pushStatus = "1"; // 1=已保存 2=待推送 3=已推送
            } else {
              this.formData.pushStatus = "2";
            }
            if (Array.isArray(this.formData.pushTags)) {
              this.formData.pushTags = this.formData.pushTags.join(",");
            }
            let url = !this.isEdit
              ? "/acb/2.0/pushManage/pushTaskAdd"
              : "/acb/2.0/pushManage/pushTaskEdit";
            const opt = {
              url: url,
              method: "post",
              config: {
                headers: {
                  "Content-Type": "application/json;charset=UTF-8",
                },
              },
              data: this.formData,
              success: (res) => {
                this.saveLoading = false;
                this.pushId = res.value;
                if (arg == 1) {
                  this.$router.push("/appPushManage");
                }
                resolve(res.value);
              },
              fail: (res) => {
                this.saveLoading = false;
                reject();
              },
            };
            this.$request(opt);
          } else {
            return false;
          }
        });
      });
    },
    // 获取用户标签
    getLabel() {
      const opt = {
        url: "/acb/2.0/labelManage/labelList",
        method: "get",
        data: { pageNum: 1, pageSize: 100 },
        success: (res) => {
          this.labelList = res.value.list;
        },
      };
      this.$request(opt);
    },
  },
  watch: {
    pushTagsList(val) {
      // console.log(val);
    },
  },
  computed: {
    pushTagsList() {
      return this.formData.pushTags;
    },
  },
};
</script>

<style lang="stylus" scoped>
>>>.hide
  .el-upload--picture-card
    display none
</style>
